import _Vue, { PluginObject } from 'vue';
import { Store } from 'vuex';
import { AuthPort, AuthPortFactory } from '@/modules/auth/index';
import { AuthModuleAdapter } from '@/store/auth/AuthModuleAdapter';
import {LicensingPort, LicensingPortFactory} from "@/modules/licensing";
import {CardPort, CardPortFactory} from "@/modules/cards";
import {CardActionsPort, CardActionsPortFactory} from "@/modules/cardActions";
import {MasterDataPort, MasterDataPortFactory} from "@/modules/masterData";
import {ScalesPort, ScalesPortFactory} from "@/modules/scales";
import {PosManagerPort, PosManagerPortFactory} from "@/modules/posManager";

interface Ports {
    auth: AuthPort;
    posManager: PosManagerPort;
    license: LicensingPort;
    card:CardPort;
    cardActions:CardActionsPort;
    masterData: MasterDataPort;
    scales: ScalesPort;
}

const portsPlugin: PluginObject<any> = {
    install(vue: typeof _Vue, store: Store<any>): void {
        // Instantiate ports
        const authPort: AuthPort = AuthPortFactory.createAuthPort({
            appId: process.env.VUE_APP_APPID || '',
            loginServiceUrl: process.env.VUE_APP_LOGIN_URL || '',
            store: new AuthModuleAdapter(store),
        });
        const posManagerPort: PosManagerPort = PosManagerPortFactory.createPosManagerPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const licensingPort: LicensingPort = LicensingPortFactory.createLicensingPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const cardPort: CardPort = CardPortFactory.createCardsPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const cardActionsPort: CardActionsPort = CardActionsPortFactory.createCardsActionsPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const masterDataPort: MasterDataPort = MasterDataPortFactory.createMasterDataPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const scalesPort: ScalesPort = ScalesPortFactory.createScalesPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
            getBranchId: () => authPort.getBranchId(),
        });
        const ports: Ports = {
            auth: authPort,
            posManager: posManagerPort,
            license : licensingPort,
            card:cardPort,
            cardActions:cardActionsPort,
            masterData: masterDataPort,
            scales: scalesPort
        };

        vue.prototype.$ports = ports;
        vue.ports = ports;
    },
};

export default portsPlugin;

// add type definitions for typescript compiler
declare module 'vue/types/vue' {
    interface Vue {
        $ports: Ports;
    }

    interface VueConstructor {
        ports: Ports;
    }
}
