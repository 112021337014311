
import {Component, Ref, Prop, Vue, Watch} from 'vue-property-decorator';
import {
    AproconFormDialog,
    AproconTable,
    ErrorHandler,
    FieldConfig, FormConfig, FormErrorHandlerParams, FormErrorHandlerResponse,
    FormsMappingProvider,
    TableConfig, TableErrorHandlerParams, TableErrorHandlerResponse
} from '@aprocon-software/aprocon-form';
import {globalEventBus} from "@/main";
import FormDialog from "@/components/general/FormDialog.vue";

@Component({
    components: {FormDialog, AproconTable, AproconFormDialog},
})
export default class GridForm extends Vue {
    @Prop()
    private parentRecordId!: number | undefined;
    private customTabSlots:any[]=[];
    private customTabs_FILIALARTIKEL:any[]=  [{
        id: 'tab-FILIALARTIKEL_PREISE',
        title: 'Preise',
        titleProp:'SUCHBEGRIFF',
        searchLabel:'Preise durchsuchen',
        newItemTitle:'',
        context: "",
        gridBrowse: 'BROWSE_FILIALARTIKELPREISE',
        type: () => import("@/components/general/GridForm.vue")
    }];
    private customTabs_ABTEILUNGEN_WARENGRUPPEN:any[]=  [{
        id: 'tab-ABTEILUNGEN_WARENGRUPPEN',
        title: 'Warengruppen',
        titleProp:'SUCHBEGRIFF',
        searchLabel:'Warengruppen durchsuchen',
        newItemTitle:'',
        context: "",
        gridBrowse: 'BROWSE_ABTEILUNGSWARENGRUPPEN',
        type: () => import("@/components/general/GridForm.vue")
    }];
    @Prop({required: true, default: ''})
    private context!: string;
    /*Props*/
    @Prop({default: true})
    private openDefaultForm!:boolean;
    @Prop({required: true, default: 'BEZEICHNUNG'})
    private itemTitleProp!: string;
    @Prop({required: true, default: 'Neuer Eintrag'})
    private newItemTitle!: string;
    @Prop({default: false})
    private fixedHeader!: boolean;
    @Prop({default: false})
    private fixedFooter!: boolean;
    @Prop({default: false})
    private multiSelect!: boolean;
    @Prop({required: false, default: ''})
    private gridName!: string;
    @Prop({required: false, default: false})
    private showBackButton!: boolean;
    @Prop({required: false, default: false})
    private showRefreshButton!: boolean;
    @Prop({required: false, default: false})
    private disableRefreshButton!: boolean;
    @Prop()
    private goBackCallback!: any;
    @Prop()
    private refreshCallback!: any;
    @Prop({default: 'Suchen'})
    private searchLabel!: string;
    @Prop({
        default: () => {
            return [];
        }
    })
    @Prop({default: []})
    private multiSelectActions!: any[];

    @Prop({
        default: () => {

            return [];
        }
    })
    private singleSelectActions!: any[];
    /*Refs*/
    @Ref()
    private readonly form!: AproconFormDialog;
    @Ref('table')
    private readonly table!: AproconTable<any>;
    private canCreate: boolean = false;
    private filterExpression: string | null = null;

    public clearSelection() : void {
        this.table.clearSelection();
    }

    private filter: string = '';
    private formName: string = '';
    private editRecord: any = {};
    private formAction: 'Create' | 'View' | 'Update' | 'Delete' = 'View';
    private errorHandler: ErrorHandler = {
        handleTableConfigError: this.handleTableError,
        handleTableLoadError: this.handleTableError,
        handleTableUnknownError: this.handleTableError,
        handleFormConfigError: this.handleFormError,
        handleFormLoadError: this.handleFormError,
        handleFormSaveError: this.handleFormError,
        handleFormUnknownError: this.handleFormError
    };
    private async handleFormError(params:FormErrorHandlerParams): Promise<FormErrorHandlerResponse > {
        if (params.error) {

            console.log(params.error);
            // logout
            if (params.error.id && params.error.id === 10003) {
                await this.$store.commit("Auth/setAuthToken", '');
                await this.$ports.auth.logout();
            }
            if (params.error.message) {
                await globalEventBus.$emit('showError', params.error.message);
            } else {
                await globalEventBus.$emit('showError', params.error);
            }

            try{
                //await this.table.form.close();
            }catch(e) {
                console.log(e);
            }
        }
        return {
            closeForm: true
        }
        //this.cancel();
    }
    private async handleTableError(params: TableErrorHandlerParams): Promise<TableErrorHandlerResponse> {
        if (params.error) {

            console.log(params.error);
            // logout
            if (params.error.id && params.error.id === 10003) {
                await this.$store.commit("Auth/setAuthToken", '');
                await this.$ports.auth.logout();
            }
            if (params.error.message) {
                await globalEventBus.$emit('showError', params.error.message);
            } else {
                await globalEventBus.$emit('showError', params.error);
            }

            try{
                //await this.table.form.close();
            }catch(e) {
                console.log(e);
            }
        }
        return {};
        //this.cancel();
    }

    private get formMapper(): FormsMappingProvider {
        return {
            mapForm: (form: FormConfig, raw: any) => {
                if(this.customTabSlots.length === 0) {
                    this.customTabSlots = [{
                        id: 'tab-FILIALARTIKEL_PREISE',
                        title: 'Preise',
                        titleProp: 'SUCHBEGRIFF',
                        searchLabel: 'Preise durchsuchen',
                        newItemTitle: '',
                        gridBrowse: 'BROWSE_FILIALARTIKELPREISE',
                        type: () => import("@/components/general/GridForm.vue")
                    }, {
                        id: 'tab-ABTEILUNGEN_WARENGRUPPEN',
                        title: 'Warengruppen',
                        titleProp: 'SUCHBEGRIFF',
                        searchLabel: 'Warengruppen durchsuchen',
                        newItemTitle: '',
                        gridBrowse: 'BROWSE_ABTEILUNGSWARENGRUPPEN',
                        type: () => import("@/components/general/GridForm.vue")
                    }];
                }
                if (form.formName === 'EDIT_ABTEILUNGEN') {
                    form.sheets.push({
                        id: 'ABTEILUNGEN_WARENGRUPPEN',
                        title: 'Warengruppen',
                        order: 10,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });

                }
                if (form.formName === 'EDIT_FILIALARTIKEL') {
                    form.sheets.push({
                        id: 'FILIALARTIKEL_PREISE',
                        title: 'Preise',
                        order: 12,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });

                }
                return form;
            },
            mapField: this.mapField
        }
    }

    private mapField(field: FieldConfig, raw: any): FieldConfig {
        if (raw.TYPE === "CALENDAR") {

            field.fieldType = () => import("@/components/CustomFormControls/DateFormField.vue");
            field.inputType = () => import("@/components/CustomFormControls/DateFormInput.vue");
            return field;
        }
        if (raw.TYPE === "CLOCK") {

            field.fieldType = () => import("@/components/CustomFormControls/TimeFormField.vue");
            field.inputType = () => import("@/components/CustomFormControls/TimeFormInput.vue");
            return field;
        }
        return field;
    }
    private get gridContext(): string {
        if (this.context.startsWith(`Filiale=${this.$store.getters["Auth/branchId"]};`)) {
            return this.context;
        }
        return `Filiale=${this.$store.getters["Auth/branchId"]};` + this.context;
    }

    /*Watcher*/
    @Watch('context')
    private contextChanged(): void {

    }

    /*Methods*/
    private formContext(): { [key: string]: string } {
        return {'EDIT_ETIKETTENTEXTE': this.context + ',' + this.filterExpression}
    }

    public refresh(full: boolean) {
        this.table.reload(full);
    }

    private async saved(): Promise<void> {
        this.$emit("saved");
    }

    private overrideConfig(tableConfig: TableConfig): TableConfig {
        if (tableConfig.tableName === this.gridName) {
            this.formName = tableConfig.formName;
            tableConfig.fixedHeader = true;
            tableConfig.fixedFooter = true;
            tableConfig.interactive = true;
            //tableConfig.interactive = true;
           // tableConfig.multiselect = this.multiSelect;
            tableConfig.multiselectActions = this.multiSelectActions;
            tableConfig.actions = this.singleSelectActions;
            this.canCreate = tableConfig.permissions.create;
        }
        if(!this.openDefaultForm) {
            tableConfig.onClickAction = 'do-nothing';
            tableConfig.onClickActionMobile = 'do-nothing';
        }
        return tableConfig;
    }

    private cancel(): void {
        this.$emit('cancel');
    }

    private async handleError(error: any): Promise<void> {
        if (error) {
            // logout
            if (error.id && error.id === 10003) {
                this.$store.commit("Auth/setAuthToken", '');
                this.$ports.auth.logout();
            }
            if (error.message) {
                globalEventBus.$emit('showError', error.message);
            } else {
                globalEventBus.$emit('showError', error);
            }
        }
        this.cancel();
    }

    public async addItem(): Promise<void> {
        this.editRecord = null;
        this.formAction = 'Create';
        await this.table.createItem();
    }


    private goBack(): void {
        if (this.goBackCallback) {
            this.goBackCallback();
        } else {
            this.$router.back();
        }
    }

    private tableLoad(args: any): void {

    }

    private async refreshGrid(): Promise<void> {
        if (this.refreshCallback) {
            await this.refreshCallback();
        } else {
            await this.table.reload(false);
        }
    }

    private clickRow(args: {value: any, id: any, formName: string,tableName: string}): void {
        this.$emit('rowItemClick', args);
    }
}
