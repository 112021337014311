import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Sales_New_Grid from "@/views/sales/Sales_New_Grid.vue";
const StartApp = (resolve: (...modules: any[]) => void) => require(['../views/StartApp.vue'], resolve);
const Masterdata = (resolve: (...modules: any[]) => void) => require(['../views/masterdata/Masterdata.vue'], resolve);
const MasterdataGrid = (resolve: (...modules: any[]) => void) => require(['../views/masterdata/MasterdataGrid.vue'], resolve);
const Scales = (resolve: (...modules: any[]) => void) => require(['../views/scales/Scales.vue'], resolve);
const ScalesGrid = (resolve: (...modules: any[]) => void) => require(['../views/scales/ScalesGrid.vue'], resolve);
const About = (resolve: (...modules: any[]) => void) => require(['../views/About.vue'], resolve);
const Stafftimes = (resolve: (...modules: any[]) => void) => require(['../views/stafftimes/Stafftimes.vue'], resolve);
const Products = (resolve: (...modules: any[]) => void) => require(['../views/products/Products.vue'], resolve);
const Customers = (resolve: (...modules: any[]) => void) => require(['../views/customers/Customers.vue'], resolve);
const Sales_New = (resolve: (...modules: any[]) => void) => require(['../views/sales/Sales_New.vue'], resolve);
const SalesNewGrid = (resolve: (...modules: any[]) => void) => require(['../views/sales/Sales_New_Grid.vue'], resolve);
const Sales = (resolve: (...modules: any[]) => void) => require(['../views/sales/Sales.vue'], resolve);
const SalesGrid = (resolve: (...modules: any[]) => void) => require(['../views/sales/SalesGrid.vue'], resolve);
const Bons = (resolve: (...modules: any[]) => void) => require(['../views/bons/Bons.vue'], resolve);
const TopsFlops = (resolve: (...modules: any[]) => void) => require(['../views/sales/TopFlop.vue'], resolve);

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
    {
        path: '/umsaetze',
        component: Sales_New,
        meta: {
            loginRequired: true,
            pageTitle: 'Umsätze',
            pageIcon: 'posmanager-presentation-business',
        }, children:[
            {
                name: 'umsaetze',
                path: '',
                redirect: '0',
            },{
                path: ':salesId',
                component: SalesNewGrid,
                meta: {
                    loginRequired: true,
                    pageTitle: 'Umsätze',
                    pageIcon: 'posmanager-cash-register',
                },
            }
        ]
    },


    {
        path: '/personalzeiten',
        component: Stafftimes,
        meta: {
            loginRequired: true,
            pageTitle: 'Personalzeiten',
            pageIcon: 'posmanager-people-employee-time',
            disabled: false,
        }
    },
    {
        path: '/artikel',
        component: Products,
        meta: {
            loginRequired: true,
            pageTitle: 'Artikel',
            pageIcon: 'posmanager-item',
        }
    },
    {
        path: '/kunden',
        component: Customers,
        meta: {
            loginRequired: true,
            pageTitle: 'Kunden',
            pageIcon: 'posmanager-people-customers',
        }
    },
    {
        path: '/waageundkasse',
        component: Scales,
        children:[
            {
                name: 'waagen',
                path: '',
                redirect: '0',
            },
            {
                path: ':scalesId',
                component: ScalesGrid,
                meta: {
                    loginRequired: true,
                    pageTitle: 'Waagen & Kassen',
                    pageIcon: 'posmanager-cash-register-gear',
                },
            }],
        meta: {
            loginRequired: true,
            pageTitle: 'Waagen & Kassen',
            pageIcon: 'posmanager-cash-register-gear',
        },
    },
    {
        path: '/stammdaten',
        component: Masterdata,
        children:[
            {
                name: 'stammdaten',
                path: '',
                redirect: '0',
            },
            {
                path: ':masterDataId',
                component: MasterdataGrid,
                meta: {
                    loginRequired: true,
                    pageTitle: 'Stammdaten',
                    pageIcon: 'posmanager-database',
                },
            }],
        meta: {
            loginRequired: true,
            pageTitle: 'Stammdaten',
            pageIcon: 'posmanager-database',
        },
    }
];
/*{
    path: '/waageundkasse',
        component: Scales,
    children:[
    {
        name: 'waageundkasse',
        path: '',
        redirect: '0',
    },
    {
        path: ':masterDataId',
        component: ScalesGrid,
        meta: {
            loginRequired: true,
            pageTitle: 'Waage & Kasse',
            pageIcon: 'fa-cash-register',
        },
    }],
    meta: {
    loginRequired: true,
        pageTitle: 'Waage & Kasse',
        pageIcon: 'fa-cash-register',
},
}*/
const otherRoutes: Array<RouteConfig> = [
    {
        path: '',
        redirect: '/umsaetze'
    },
    {
        path: '/',
        redirect: '/umsaetze'
    },
    {
        path: '/About',
        name: 'About',
        component: About,
        meta: {
            pageTitle: 'Version',
            pageIcon: 'fa-question',
        },
    },
    {
        path: '/umsaetze/topsundflops',
        component: TopsFlops,
        meta: {
            loginRequired: true,
            pageTitle: 'Umsätze / Tops & Flops',
            pageIcon: 'fa-file',
        },
    },
    {
        path: '/deviceConfig',
        redirect: '/umsaetze'
    },{
        path: '/start',
        name: 'start',
        component: StartApp,
        meta: {
            loginRequired: false,
            pageTitle: 'Start',
        },
    },

];

const router = new VueRouter({
    mode: 'history',
    routes: routes.concat(otherRoutes),
});


export default router;
