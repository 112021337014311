import type {AuthRepository} from '../adapter/AuthRepository';
import type {LoginRepository} from '../adapter/LoginRepository';
import axios from "axios";

export class AuthService {
private defaultPermissions =  {
    "ZUGRIFF" : 1,
    "KASSENDATEN" : 1,
    "DATENSENDEN" : 1,
    "PERSONALZEITEN" : 1,
    "COMMON": {
        "REPORTS" : 1,
        "ARTIKEL" : 1,
        "KUNDEN" : 1,
        "STAMMDATEN" : 1
    }
};
    private readonly authRepository!: AuthRepository;
    private readonly loginRepository!: LoginRepository;
    private readonly appId!: string;


    constructor(params: {
        authRepository: AuthRepository
        loginRepository: LoginRepository
        appId: string,
    }) {
        this.authRepository = params.authRepository;
        this.loginRepository = params.loginRepository;
        this.appId = params.appId;
    }

    public async login(): Promise<boolean> {

        if (await this.authRepository.isLoggedIn()) {
            return true;
        }
        const response = this.loginRepository.login({
            appId: this.appId,
            serviceUrl: this.authRepository.getServiceUrl(),
        });
        const loggedIn = await this.authRepository.testCredentials({
            serviceUrl: response?.service,
            authToken: response?.token,
            userId: response?.userId,
            branchId: response?.branchId,
        }).then(async (retrunValue) => {
            console.log(retrunValue);
            if (retrunValue) {
                const config = await this.getUserConfig(true);
                console.log(config);
            }
            return retrunValue;
        });

        return loggedIn;
    }

    public logout() {
        this.loginRepository.logout({
            appId: this.appId,
            serviceUrl: this.getServiceUrl(),
        });
        localStorage.setItem('userConfig', '');
    }


    public async isLoggedIn(): Promise<boolean> {
        return this.authRepository.isLoggedIn();
    }

    public getServiceUrl(): string {
        return this.authRepository.getServiceUrl();
    }

    public getAuthToken(): string {
        return this.authRepository.getAuthToken();
    }

    public getUserId(): string {
        return this.authRepository.getUserId();
    }

    public getBranchId(): string {
        return this.authRepository.getBranchId();
    }

    public async getBranches(): Promise<{ Nummer: number, Name: string }[]> {
        const userId = this.authRepository.getUserId();
        const response = await axios.post(`${this.authRepository.getServiceUrl()}/Authentication/Tbl_Filialen`, {UserID: userId});
        return response.data.Tbl_Filialen_response.Tbl_Filialen;
    }
    public async getUserConfig(isAppCall: boolean): Promise<{ config: any, permissions: any }> {
        const sid = this.getAuthToken();
        try {
            let userConfig = undefined;
            try {
                userConfig = JSON.parse(localStorage.getItem('Auth/userConfig') || '');
            } catch {

            }

            if (isAppCall || !userConfig || (!userConfig.config && !userConfig.permissions)) {


                const serviceUrl = this.getServiceUrl();
                const config = axios.post(`${serviceUrl}/Common/get_App_Config`, {AppID: this.appId}).then(response => {
                    try {
                        if (response?.data?.get_App_Config_response?.ServiceErrors) {
                            console.error(response.data.get_App_Config_response.ServiceErrors[0].ERRORRECOMMENDATION)
                            throw(new Error(response.data.get_App_UserRights_response.ServiceErrors[0].ERRORRECOMMENDATION))
                        }
                        if (response?.data?.get_App_Config_response?.Configuration && response?.data?.get_App_Config_response?.Configuration[this.appId + ".CONF"]) {
                            return response?.data?.get_App_Config_response?.Configuration[this.appId + ".CONF"];
                        }
                    } catch (e: any) {
                        return {};
                    }
                });

                if(sid) {
                    let permissionsRequest = axios.post(`${serviceUrl}/Authentication/get_App_UserRights`, {
                        AppID: this.appId,
                        SID: sid
                    }).then(response => {
                        let permissions = this.defaultPermissions;

                        try {
                            if (response?.data?.get_App_UserRights_response?.ServiceErrors) {
                                console.error(response.data.get_App_UserRights_response.ServiceErrors[0].ERRORRECOMMENDATION);
                                throw(new Error(response.data.get_App_UserRights_response.ServiceErrors[0].ERRORRECOMMENDATION))
                            }
                            if (response?.data?.get_App_UserRights_response?.AppUserRights["APRO.POS.USERRIGHTS"]) {
                                //throw('DefaultPermissions');
                                permissions = response?.data?.get_App_UserRights_response?.AppUserRights["APRO.POS.USERRIGHTS"];

                            }
                            if (response?.data?.get_App_UserRights_response?.AppUserRights["COMMON.USERRIGHTS"]) {

                                //throw('DefaultPermissions');
                                permissions.COMMON = response?.data?.get_App_UserRights_response?.AppUserRights["COMMON.USERRIGHTS"];

                            }

                        } catch (e: any) {
                            console.error(e);
                            // permissions = {
                            //     "ZUGRIFF": 1,
                            //     "REPORTS": 0,
                            //     "KASSENDATEN": 1,
                            //     "DATENSENDEN": 0,
                            //     "PERSONALZEITEN": 0,
                            //     "ARTIKEL": 0,
                            //     "KUNDEN": 0,
                            //     "STAMMDATEN": 1
                            // };
                        } finally {
                            return permissions;

                        }

                    });
                    userConfig = await Promise.all([config, permissionsRequest]).then(values => {
                        return {config: values[0], permissions: values[1]};
                    });
                }else{
                    userConfig = await Promise.all([config]).then(values => {
                        return {config: values[0]};
                    });
                }

                const configString = JSON.stringify(userConfig);
                localStorage.setItem('Auth/userConfig', configString);

            }
            return userConfig

        } catch (e) {
            if (e && e.mesage) {
                console.log(e.message)
            }
            return {config: null, permissions: null};
        }finally {

        }
    }
    public async getUserConfigOrg(isAppCall: boolean): Promise<{ config: any, permissions: any }> {
        const sid = this.getAuthToken();
        try {
            let userConfig = undefined;
            try {
                userConfig = JSON.parse(localStorage.getItem('Auth/userConfig') || '');
            } catch {

            }

            if (isAppCall || !userConfig || (!userConfig.config && !userConfig.permissions)) {


                const serviceUrl = this.getServiceUrl();
                const config = axios.post(`${serviceUrl}/Common/get_App_Config`, {AppID: this.appId}).then(response => {
                    try {
                        if (response?.data?.get_App_Config_response?.ServiceErrors) {
                            console.error(response.data.get_App_Config_response.ServiceErrors[0].ERRORRECOMMENDATION)
                            throw(new Error(response.data.get_App_UserRights_response.ServiceErrors[0].ERRORRECOMMENDATION))
                        }
                        if (response?.data?.get_App_Config_response?.Configuration && response?.data?.get_App_Config_response?.Configuration[this.appId + ".CONF"]) {
                            return response?.data?.get_App_Config_response?.Configuration[this.appId + ".CONF"];
                        }
                    } catch (e: any) {
                        return {};
                    }
                });

                if(sid) {
                    let permissionsRequest = axios.post(`${serviceUrl}/Authentication/get_App_UserRights`, {
                        AppID: this.appId,
                        SID: sid
                    }).then(response => {
                        // "get_App_UserRights_response" : {
                        //     "AppUserRights" : {
                        //         "APRO.POS.USERRIGHTS" : {
                        //             "ZUGRIFF" : 1,
                        //                 "KASSENDATEN" : 1,
                        //                 "DATENSENDEN" : 1,
                        //                         //                 "PERSONALZEITEN" : 1
                        //         },
                        //         "COMMON.USERRIGHTS" : {
                        //             "REPORTS" : 1,
                        //                 "ARTIKEL" : 1,
                        //                 "KUNDEN" : 1,
                        //                 "STAMMDATEN" : 1
                        //         }
                        //     }
                        // }
                        let permissions = this.defaultPermissions;

                        try {
                            if (response?.data?.get_App_UserRights_response?.ServiceErrors) {
                                console.error(response.data.get_App_UserRights_response.ServiceErrors[0].ERRORRECOMMENDATION);
                                throw(new Error(response.data.get_App_UserRights_response.ServiceErrors[0].ERRORRECOMMENDATION))
                            }
                            if (response?.data?.get_App_UserRights_response?.AppUserRights["APRO.POS.USERRIGHTS"]) {
                                //throw('DefaultPermissions');
                                permissions = response?.data?.get_App_UserRights_response?.AppUserRights["APRO.POS.USERRIGHTS"];

                            }
                            if (response?.data?.get_App_UserRights_response?.AppUserRights["COMMON.USERRIGHTS"]) {
                                //throw('DefaultPermissions');
                                permissions.COMMON = response?.data?.get_App_UserRights_response?.AppUserRights["COMMON.USERRIGHTS"];

                            }

                        } catch (e: any) {
                            console.error(e);
                            // permissions = {
                            //     "ZUGRIFF": 1,
                            //     "REPORTS": 0,
                            //     "KASSENDATEN": 1,
                            //     "DATENSENDEN": 0,
                            //     "PERSONALZEITEN": 0,
                            //     "ARTIKEL": 0,
                            //     "KUNDEN": 0,
                            //     "STAMMDATEN": 1
                            // };
                        } finally {
                            return permissions;

                        }

                    });
                    userConfig = await Promise.all([config, permissionsRequest]).then(values => {
                        return {config: values[0], permissions: values[1]};
                    });
                }else{
                    userConfig = await Promise.all([config]).then(values => {
                        return {config: values[0]};
                    });
                }

                const configString = JSON.stringify(userConfig);
                localStorage.setItem('Auth/userConfig', configString);

            }
            return userConfig

        } catch (e) {
            if (e && e.mesage) {
                console.log(e.message)
            }
            return {config: null, permissions: null};
        }finally {

        }
    }

    public async switchBranch(newBranchId:number): Promise<boolean> {
        const sid = this.getAuthToken();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: newBranchId
            }
            const response = await axios.post(`${this.getServiceUrl()}/Authentication/set_Filiale`, data);
            if (response.data.set_Filiale_response?.ServiceErrors) {
                throw new Error(response.data.set_Filiale_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.set_Filiale_response === null;
        } else {
            return Promise.reject();
        }
    }

    public async getSessionInfo(sid:string): Promise<any> {
        return this.authRepository.getSessionInfo(sid);
    }
}
