
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {routes} from '@/router';
import LogoutDialog from '@/components/LogoutDialog.vue';
import {RouteConfig} from "vue-router";

@Component({components: {LogoutDialog}})
export default class Menu extends Vue {
    private loading:boolean = true;

    @Prop({default: false})
    private showLogo!: boolean;

    @Prop()
    private userConfig!:any;
    private routes = routes;

    private userRoutes:RouteConfig[] = [];
    @Watch('userConfig')
    private async setRoutes() : Promise<void> {
        try {
            const configResult = JSON.parse(localStorage.getItem('Auth/userConfig') || '');
            this.userRoutes=[];
            this.loading = true;
            routes.forEach((r) => {
                if (r.path == "/artikel") {
                    if (configResult.permissions.COMMON.ARTIKEL === 1) {
                        this.userRoutes.push(r);
                    }
                } else if (r.path === "/kunden") {
                    if (configResult.permissions.COMMON.KUNDEN === 1) {
                        this.userRoutes.push(r);
                    }
                } else if (r.path === "/personalzeiten") {
                    if (configResult.permissions.PERSONALZEITEN === 1) {
                        this.userRoutes.push(r);
                    }
                }else {
                    this.userRoutes.push(r);
                }
            });
        } catch (e: any) {

        } finally {
            this.loading = false;
        }
    }
    // private isFontAwesome(iconName: string): boolean {
    //     if (iconName) {
    //         const ret =  iconName.toLowerCase().startsWith('fa-') || iconName.toLowerCase().startsWith('aprocon-');
    //         return ret;
    //     }
    //     return false;
    // }
    //
    // private iconPath(iconName: string): string {
    //     return require(`@/assets/icons/${iconName}.svg`);
    // }

    private isDisabled(element: RouteConfig): boolean {
        try {
            //console.log(element);
            const configResult = JSON.parse(localStorage.getItem('Auth/userConfig') || '');

            if (element.path === "/personalzeiten" && configResult.permissions) {
                return configResult.permissions?.PERSONALZEITEN === 0;
            }
            if (element.path === "/kunden" && configResult.permissions && configResult.permissions.COMMON) {
                return configResult.permissions.COMMON?.KUNDEN === 0;
            }
            // if(element.path === "/artikel" && configResult.permissions) {
            //     return configResult.permissions?.ARTIKEL === 0;
            // }
            // if(element.path === "/stammdaten" && configResult.permissions) {
            //     return configResult.permissions?.STAMMDATEN === 0;
            // }
            return false;
        } catch {
            return false;
        }
    }

    private logoutApp() {
        if (this.showLogOut) {
            localStorage.setItem('Auth/userConfig', '');
            this.$store.commit("Auth/setAuthToken", '');
            this.resetCaller();
            this.$ports.auth.logout();
        } else {
            this.resetCaller();
        }
    }

    async mounted() {
        await this.setRoutes();
    }

    private get showLogOut(): boolean {
        return !this.$store.getters["Base/caller"];
    }

    private resetCaller(): void {
        this.$store.commit("Base/setCaller", "");
    }

    private getCallerUrl() {
        let caller = this.$store.getters['Base/caller'];
        if (!caller) {
            return "#";
        }
        return caller + decodeURIComponent("?bid=" + this.$ports.auth.getBranchId());
    }
}
