
import {Component, Ref, Prop, Vue, Watch} from 'vue-property-decorator';
import {
    AproconForm,
    CloseInterrupter,
    ErrorHandler,
    FormConfig, FormErrorHandlerParams, FormErrorHandlerResponse,
    FormsMappingProvider, TableErrorHandlerParams,
    TableErrorHandlerResponse
} from '@aprocon-software/aprocon-form';
import {globalEventBus} from "@/main";

@Component({
    components: { AproconForm },
})
export default class FormDialog extends Vue {
private errors:any[]=[];
    @Prop({required:true, default: 'BEZEICHNUNG'})
    private itemTitleProp!:string;
    @Prop({required:true, default: 'Neuer Eintrag'})
    private newItemTitle!:string;
    @Prop({required:true, default: false})
    private value!: boolean;
    @Ref('form')
    private readonly form!: AproconForm<any>;
    @Prop({required:true, default:''})
    private readonly formName!: string;
    @Prop({required:true, default:''})
    private context!: string;
    @Prop({required:true, default: {}})
    private editRecord!: any;
    @Prop({required:true, default: 'View'})
    private formAction!: 'Create' | 'View' | 'Update' | 'Delete';
    private errorHandler: ErrorHandler = {
        handleTableConfigError: this.handleTableError,
        handleTableLoadError: this.handleTableError,
        handleTableUnknownError: this.handleTableError,
        handleFormConfigError: this.handleFormError,
        handleFormLoadError: this.handleFormError,
        handleFormSaveError: this.handleFormError,
        handleFormUnknownError: this.handleFormError
    };

    private async handleFormError(params:FormErrorHandlerParams): Promise<FormErrorHandlerResponse > {
        if (params.error) {

            console.log(params.error);
            // logout
            if (params.error.id && params.error.id === 10003) {
                await this.$store.commit("Auth/setAuthToken", '');
                await this.$ports.auth.logout();
            }
            if (params.error.message) {
                await globalEventBus.$emit('showError', params.error.message);
            } else {
                await globalEventBus.$emit('showError', params.error);
            }

            try{
                //await this.table.form.close();
            }catch(e) {
                console.log(e);
            }
        }
        return {

        }
        //this.cancel();
    }
    private async handleTableError(params: TableErrorHandlerParams): Promise<TableErrorHandlerResponse> {
        if (params.error) {

            console.log(params.error);
            // logout
            if (params.error.id && params.error.id === 10003) {
                await this.$store.commit("Auth/setAuthToken", '');
                await this.$ports.auth.logout();
            }
            if (params.error.message) {
                await globalEventBus.$emit('showError', params.error.message);
            } else {
                await globalEventBus.$emit('showError', params.error);
            }

            try{
                //await this.table.form.close();
            }catch(e) {
                console.log(e);
            }
        }
        return {};
        //this.cancel();
    }
    private mapper: FormsMappingProvider<any> = {
        onInput: (params:any) => this.onInput(params),
        onInit: (params:any) => this.onInit(params),
        onDataChanged: (params:any) => this.onDataChanged(params),
    }

    private async saved():Promise<void> {
        this.$emit("saved");

    }
    private get formTitle() : string {
        return this.editRecord[this.itemTitleProp] || this.newItemTitle;
    }
    private cancel():void {
        this.$emit('cancel');
    }

    private onInit({ config, data }:{config:any,data:any}): any {
        return data;
    }

    private onInput({ config, data, changes }:{ config:any, data:any, changes:any }): Partial<any> {
        const changesOverride: Partial<any> = {};
        return Object.assign(changes, changesOverride);
    }

    private onDataChanged({ data, config }:{ config:any, data:any }): FormConfig {
        return config;
    }

}
